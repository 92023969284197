import { Pipe, PipeTransform } from '@angular/core'
import { formatNumber } from 'libphonenumber-js'

@Pipe({ name: 'phone', standalone: true })
export class PhonePipe implements PipeTransform {
  transform(phoneNumber: string): string {
    // Format Norwegian numbers as +47 *** ** ***.
    return !phoneNumber
      ? ''
      : phoneNumber.includes('+47') && phoneNumber.length === 11
        ? phoneNumber.split('').toSpliced(3, 0, ' ').toSpliced(7, 0, ' ').toSpliced(10, 0, ' ').join('')
        : formatNumber(phoneNumber, 'INTERNATIONAL')
  }
}
